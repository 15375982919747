.blog-post-card3-blog-post-card {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: row;
  justify-content: center;
}
.blog-post-card3-container {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.blog-post-card3-text {
  color: var(--dl-color-gray-500);
  font-style: normal;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  text-transform: uppercase;
}
.blog-post-card3-text1 {
  color: #ffffff;
  margin-bottom: var(--dl-space-space-threeunits);
}
.blog-post-card3-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: flex-start;
}
.blog-post-card3-text2 {
  color: #d4d4d4;
  margin-bottom: var(--dl-space-space-halfunit);
}
.blog-post-card3-image {
  width: 600px;
  box-shadow: 5px 5px 10px 0px #000000;
  object-fit: cover;
  transition: 0.3s;
  border-radius: var(--dl-radius-radius-radius8);
}
.blog-post-card3-image:hover {
  transform: scale(1.1);
}




@media(max-width: 991px) {
  .blog-post-card3-image {
    width: 50%;
  }
}
@media(max-width: 767px) {
  .blog-post-card3-blog-post-card {
    flex-direction: column;
    justify-content: space-between;
  }
  .blog-post-card3-container {
    width: 100%;
  }
  .blog-post-card3-text {
    margin-bottom: var(--dl-space-space-unit);
  }
  .blog-post-card3-text1 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .blog-post-card3-container1 {
    margin-bottom: var(--dl-space-space-twounits);
  }
  .blog-post-card3-image {
    width: 100%;
  }
}
