.how-to-join-page-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background-color: #07081d;
}
.how-to-join-page-hero {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.how-to-join-page-hero1 {
  height: 285px;
  position: relative;
}
.how-to-join-page-container01 {
  gap: var(--dl-space-space-oneandhalfunits);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.how-to-join-page-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.how-to-join-page-hero-heading {
  max-width: 800px;
  text-align: center;
}
.how-to-join-page-steps {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #07081d;
}
.how-to-join-page-text {
  color: #ffffff;
  align-self: center;
  padding-right: var(--dl-space-space-threeunits);
}
.how-to-join-page-container02 {
  flex: 0 0 auto;
  width: 851px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.how-to-join-page-step {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.how-to-join-page-container03 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.how-to-join-page-line {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.how-to-join-page-container04 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.how-to-join-page-icon {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.how-to-join-page-line1 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.how-to-join-page-container05 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-text04 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-text05 {
  color: #d6d6d6;
  font-size: 0.75rem;
  text-align: center;
}
.how-to-join-page-step1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.how-to-join-page-container06 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.how-to-join-page-line2 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.how-to-join-page-container07 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.how-to-join-page-icon02 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.how-to-join-page-line3 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.how-to-join-page-container08 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-text08 {
  color: rgb(255, 255, 255);
  width: 139px;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-text09 {
  color: #d6d6d6;
  font-size: 0.75rem;
  text-align: center;
}
.how-to-join-page-step2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.how-to-join-page-icon04 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}

.how-to-join-page-text12 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-text13 {
  color: #d6d6d6;
  font-size: 0.75rem;
  text-align: center;
}
.how-to-join-page-step3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.how-to-join-page-line6 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-900);
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.how-to-join-page-container13 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.how-to-join-page-icon07 {
  fill: var(--dl-color-gray-500);
  width: 24px;
  height: 24px;
}
.how-to-join-page-line7 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.how-to-join-page-container14 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-text16 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-text17 {
  color: rgb(214, 214, 214);
  font-size: 0.75rem;
  text-align: center;
}
.how-to-join-page-separator1 {
  flex: 0 0 auto;
  width: 95%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: var(--dl-space-space-threeunits);
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: var(--dl-space-space-threeunits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.how-to-join-page-container15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.how-to-join-page-logo {
  text-decoration: none;
}
.how-to-join-page-nav {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.how-to-join-page-nav12 {
  text-decoration: none;
}
.how-to-join-page-nav22 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-to-join-page-nav32 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-to-join-page-nav42 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-to-join-page-separator2 {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: var(--dl-color-gray-900);
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.how-to-join-page-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.how-to-join-page-link {
  text-decoration: none;
}
.how-to-join-page-icon-group {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.how-to-join-page-image {
  width: 49px;
  height: 35px;
  opacity: 0.5;
  object-fit: cover;
  animation-name: tada;
  animation-delay: 0s;
  animation-duration: 3000ms;
  animation-direction: normal;
  animation-iteration-count: 9999;
  animation-timing-function: ease;
}
.how-to-join-page-header {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.how-to-join-page-header1 {
  width: 100%;
  display: flex;
  z-index: 100;
  position: fixed;
  align-items: center;
  flex-direction: column;
  background-color: var(--dl-color-gray-white);
}
.how-to-join-page-logo1 {
  text-decoration: none;
}
.how-to-join-page-container17 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.how-to-join-page-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.how-to-join-page-burger-menu {
  display: none;
}
.how-to-join-page-icon09 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.how-to-join-page-mobile-menu1 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
}
.how-to-join-page-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.how-to-join-page-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.how-to-join-page-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.how-to-join-page-icon11 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.how-to-join-page-links {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.how-to-join-page-nav121 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-nav221 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-nav321 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-nav421 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-nav52 {
  margin-bottom: var(--dl-space-space-unit);
}
.how-to-join-page-buttons {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.how-to-join-page-icon13 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.how-to-join-page-icon15 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.how-to-join-page-icon17 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.how-to-join-page-nav521 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-to-join-page-nav222 {
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.how-to-join-page-nav122 {
  text-decoration: none;
}
.how-to-join-page-nav123 {
  text-decoration: none;
}
.how-to-join-page-image1 {
  width: 49px;
  height: 35px;
  opacity: 0.5;
  object-fit: cover;
  transition: 0.3s;
  animation-name: tada;
  animation-delay: 0s;
  text-decoration: none;
  animation-duration: 3000ms;
  animation-direction: normal;
  animation-iteration-count: 9999;
  animation-timing-function: ease;
}
.how-to-join-page-image1:hover {
  opacity: 1;
}
@media(max-width: 991px) {
  .how-to-join-page-hero1 {
    flex-direction: column;
  }
  .how-to-join-page-container01 {
    align-items: center;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
  }
  .how-to-join-page-hero-heading {
    text-align: center;
  }
  .how-to-join-page-text {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .how-to-join-page-hero1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .how-to-join-page-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-to-join-page-steps {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .how-to-join-page-container02 {
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .how-to-join-page-step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .how-to-join-page-container03 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .how-to-join-page-line1 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .how-to-join-page-container05 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .how-to-join-page-text04 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-text05 {
    text-align: left;
  }
  .how-to-join-page-step1 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .how-to-join-page-container06 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .how-to-join-page-line2 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .how-to-join-page-line3 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .how-to-join-page-container08 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .how-to-join-page-text08 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-text09 {
    text-align: left;
  }
  .how-to-join-page-step2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .how-to-join-page-container09 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .how-to-join-page-line4 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .how-to-join-page-line5 {
    border-color: var(--dl-color-gray-900);
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .how-to-join-page-container11 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .how-to-join-page-text12 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-text13 {
    text-align: left;
  }
  .how-to-join-page-step3 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .how-to-join-page-container12 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .how-to-join-page-line6 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .how-to-join-page-line7 {
    border-color: transparent;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .how-to-join-page-container14 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .how-to-join-page-text16 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-text17 {
    text-align: left;
  }
  .how-to-join-page-separator1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-to-join-page-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .how-to-join-page-separator2 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-to-join-page-container16 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .how-to-join-page-link {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-to-join-page-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .how-to-join-page-desktop-menu {
    display: none;
  }
  .how-to-join-page-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .how-to-join-page-nav121 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-nav221 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-nav321 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-nav421 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-nav52 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 479px) {
  .how-to-join-page-hero1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .how-to-join-page-container01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .how-to-join-page-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-to-join-page-steps {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .how-to-join-page-container02 {
    padding-left: 0px;
  }
  .how-to-join-page-container05 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .how-to-join-page-text04 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .how-to-join-page-container08 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .how-to-join-page-text08 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .how-to-join-page-container11 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .how-to-join-page-text12 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .how-to-join-page-container14 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .how-to-join-page-text16 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .how-to-join-page-separator1 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-to-join-page-footer {
    padding: var(--dl-space-space-unit);
  }
  .how-to-join-page-separator2 {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-to-join-page-container16 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .how-to-join-page-link {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .how-to-join-page-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .how-to-join-page-mobile-menu1 {
    padding: 16px;
  }
}


.how-to-join-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.how-to-join-page-hero {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 20px;
}

.how-to-join-page-steps {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}


.how-to-join-page-step {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px; 
}


.glowing-text {
  text-shadow: 
    0 0 3px rgba(255, 255, 255, 0.5),
    0 0 3px rgba(255, 255, 255, 0.7);
  animation: glowEffect 3s infinite alternate;
}

.glowing-text-weak {
  text-shadow: 
    0 0 3px rgba(255, 255, 255, 0.5),
    0 0 3px rgba(255, 255, 255, 0.7);
  animation: glowEffectWeak 3s infinite alternate;
}

@keyframes glowEffect {
  from {
    text-shadow: 
      0 0 1px #ffffff,
      0 0 1px #ffffff;
  }
  to {
    text-shadow: 
      0 0 5px #ffffff,
      0 0 5px #ffffff;
  }
}

@keyframes glowEffectWeak {
  from {
    text-shadow: 
      0 0 2px #ffffff,
      0 0 2px #ffffff;
  }
  to {
    text-shadow: 
      0 0 3px #ffffff;
  }
}
