.feature-card-feature-card {
  transition: 0.3s;
}
.feature-card-feature-card:hover {
  transform: scale(1.05);
}
.feature-card-container {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card-text {
  font-weight: 600;
  line-height: 28px;
}
.feature-card-root-class-name {
  margin-right: var(--dl-space-space-unit);
}
.feature-card-root-class-name1 {
  margin-right: var(--dl-space-space-unit);
}
@media(max-width: 767px) {
  .feature-card-feature-card {
    flex-direction: row;
  }
  .feature-card-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card-container {
    flex-direction: column;
  }
}
